import {AppliedDiscount, Coupon, MultiCurrencyPrice} from '@wix/ecom_current-cart';
import {AppliedCouponModel} from '../models/AppliedCoupon.model';

type CouponWithPrivateFields = Coupon & {amount: MultiCurrencyPrice};

export const toAppliedCouponSDK = (appliedDiscounts: AppliedDiscount[]) => {
  const coupon = appliedDiscounts.find((discount) => discount.coupon);
  return coupon
    ? AppliedCouponModel.fromSDK(appliedDiscounts.find((discount) => discount.coupon).coupon as CouponWithPrivateFields)
    : undefined;
};
